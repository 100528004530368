"use client"
import { TypographyMuted } from "@/components/ui/typography"
import { GenerationsTableRowWithUsersProfile } from "@/utils/types/database"
import { GenerationsPopoverSectionItem } from "@/components/navigation/generations-popover-section-item"

interface GenerationsPopoverSectionProps {
  generations: GenerationsTableRowWithUsersProfile[]
}

export function GenerationsPopoverSection(
  props: GenerationsPopoverSectionProps,
) {
  const { generations } = props

  if (!generations.length) {
    return <TypographyMuted>No recent generations</TypographyMuted>
  }

  return (
    <div>
      {generations.map((generation) => {
        return (
          <GenerationsPopoverSectionItem
            generation={generation}
            key={generation.id}
          />
        )
      })}
    </div>
  )
}
