"use client"
import { useEffect, useMemo, useState } from "react"
import { GenerationsPopoverSection } from "@/components/navigation/generations-popover-section"
import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import {
  TypographyMuted,
  TypographyP,
  TypographySmall,
} from "@/components/ui/typography"
import { supabaseClient } from "@/services/supabase/supabase-client"
import { useRootStore } from "@/store/root-store"
import {
  GenerationsTableRow,
  GenerationsTableRowWithUsersProfile,
} from "@/utils/types/database"
import { User } from "@supabase/supabase-js"
import {
  CrownIcon,
  DollarSignIcon,
  LogOutIcon,
  MessageCircle,
  MessageCircleQuestionIcon,
  MoveRightIcon,
  TelescopeIcon,
  UserIcon,
} from "lucide-react"
import { Link } from "@/lib/i18n"
import { useRouter } from "@/lib/i18n"
import { toast } from "sonner"
import take from "lodash/take"
import { Badge } from "@/components/ui/badge"
import { IconJarLogoIcon } from "@radix-ui/react-icons"
import { Skeleton } from "@/components/ui/skeleton"
import { cn } from "@/lib/utils"
import { ScrollArea } from "@/components/ui/scroll-area"
import { deleteCookie, getCookies } from "cookies-next"
import posthog from "posthog-js"
import { AccountPopoverPendingGenerations } from "@/components/navigation/account-popover-pending-generations"

interface AccountPopoverContentBodyProps {
  username: string
  user: User
}

export function AccountPopoverContentBody(
  props: AccountPopoverContentBodyProps,
) {
  const { username, user } = props
  const [isFetchingMyRecentGenerations, setIsFetchingMyRecentGenerations] =
    useState(true)
  const [isFetchingMyPendingGenerations, setIsFetchingMyPendingGenerations] =
    useState(true)

  const router = useRouter()

  const myRecentGenerations = useRootStore((state) => state.myRecentGenerations)
  const myPendingGenerations = useRootStore(
    (state) => state.myPendingGenerations,
  )
  const {
    setMyRecentGenerations,
    setMyPendingGenerations,
    resetGenerateStore,
    resetLikesStore,
    resetMyGenerationsStore,
  } = useRootStore((state) => state)

  const onSignOutClick = async () => {
    const { error } = await supabaseClient.auth.signOut()

    if (error) {
      // if there's an error logging out,
      // something went wrong so let's just clear
      // the supabase cookies so the user isn't stuck in a
      // weird auth state.
      console.error("error signing out", error)
      // clear all cookies cuz something went wrong.
      const cookies = getCookies()
      Object.keys(cookies).forEach((cookieName) => {
        if (cookieName.startsWith("sb-")) {
          console.log("deleting cookie", cookieName)
          deleteCookie(cookieName, {
            path: "/",
            maxAge: -1,
          })
        }
      })
    }

    resetGenerateStore()
    resetLikesStore()
    resetMyGenerationsStore()

    posthog.reset()

    deleteCookie("posthogBootstrapData", {
      path: "/",
      maxAge: -1,
    })

    router.push("/api/auth/logout", {
      scroll: false,
    })

    router.refresh()

    toast.success("You have been signed out", {
      position: "bottom-center",
    })
  }

  const sampleRecentGenerations = useMemo(() => {
    return take(myRecentGenerations, 3)
  }, [myRecentGenerations])

  const hasRecentGenerations = Boolean(myRecentGenerations?.length)
  const hasPendingGenerations = Boolean(myPendingGenerations?.length)
  const appMetadata = user?.app_metadata
  const isPro = appMetadata?.is_pro
  const fapCoins = appMetadata?.fap_coins || 0
  const freeFapCoins = appMetadata?.fap_coins_free || 0
  const oneOffFapCoins = appMetadata?.one_off_fap_coins || 0

  const totalFapCoins = fapCoins + freeFapCoins + oneOffFapCoins

  const fapCoinsUsed = user?.app_metadata.fap_coins_used || 0

  const fapCoinsLeft = totalFapCoins - fapCoinsUsed

  const formattedFapCoinsLeft = new Intl.NumberFormat().format(fapCoinsLeft)

  useEffect(() => {
    const fetchRecentGenerations = async () => {
      try {
        const { data: generations, error } = await supabaseClient
          .from("generations")
          .select(
            `
          *
          `,
          )
          .order("created_at", { ascending: false })
          .in("status", ["DELIVERED", "FAILED"])
          .eq("owner_id", user?.id)
          .range(0, 5)
          .returns<GenerationsTableRow[]>()

        if (error) {
          console.error("error", error)
          return
        }

        setMyRecentGenerations(
          generations as GenerationsTableRowWithUsersProfile[],
        )
      } catch (error) {
        console.error("error", error)
      } finally {
        setIsFetchingMyRecentGenerations(false)
      }
    }

    fetchRecentGenerations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, setMyRecentGenerations])

  useEffect(() => {
    const fetchPendingGenerations = async () => {
      try {
        const { data: generations, error } = await supabaseClient
          .from("generations")
          .select(
            `
          *
          `,
          )
          .order("created_at", { ascending: false })
          .in("status", ["GENERATING", "DELIVERING", "PENDING"])
          .eq("owner_id", user?.id)
          .range(0, 5)
          .returns<GenerationsTableRow[]>()

        if (error) {
          console.error("error", error)
          return
        }

        if (generations) {
          setMyPendingGenerations(
            generations as GenerationsTableRowWithUsersProfile[],
          )
        }
      } catch (error) {
        console.error("error", error)
      } finally {
        setIsFetchingMyPendingGenerations(false)
      }
    }

    fetchPendingGenerations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, setMyPendingGenerations])

  return (
    <ScrollArea>
      <div>
        {isPro ? (
          <Badge variant="secondary" className="mb-2">
            <CrownIcon size={16} className="mr-1" />
            Pro
          </Badge>
        ) : (
          <Link href="/pricing">
            <Badge variant="outline" className="mb-2">
              Free
            </Badge>
          </Link>
        )}
      </div>
      <div className="mb-2 md:mb-4">
        <div className="flex justify-between items-center gap-2">
          <div className="max-w-40">
            <TypographyP>{username}</TypographyP>
            <TypographyMuted className="truncate">{user.email}</TypographyMuted>
          </div>
          <div>
            <Link href="/account#subscription">
              <Badge variant={isPro ? "outline" : "secondary"} size="md">
                <IconJarLogoIcon className="mr-1" />
                <span className="text-sm">{formattedFapCoinsLeft}</span>
              </Badge>
            </Link>
          </div>
        </div>
      </div>

      <Separator className="mb-2 md:mb-4" />

      {isFetchingMyRecentGenerations && (
        <div className="mb-2">
          <div className="mb-2">
            <TypographySmall>Recent Generations</TypographySmall>
          </div>
          {Array.from({ length: 3 }, (_, index) => (
            <div key={index}>
              <div className="flex flex-row items-start justify-start h-14 mb-2">
                <div className="aspect-video w-14 h-full mr-3">
                  <Skeleton className="h-full w-full rounded-lg" />
                </div>
                <div className="flex flex-col h-full justify-between">
                  <div>
                    <Skeleton className="w-24 h-6 rounded-lg" />
                  </div>
                  <Skeleton className="w-44 h-3 rounded-lg" />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {!isFetchingMyRecentGenerations && hasRecentGenerations && (
        <>
          <div className="mb-2">
            <div className="mb-2">
              <TypographySmall>Recent Generations</TypographySmall>
            </div>
            <GenerationsPopoverSection generations={sampleRecentGenerations} />
          </div>
        </>
      )}

      {hasRecentGenerations && (
        <>
          <Link href="/generations" className="group/link">
            <Button
              size="sm"
              variant="link"
              className="text-sm text-muted group-hover/link:text-primary"
            >
              View All
            </Button>
            <MoveRightIcon
              size={16}
              className={cn(
                "inline ml-1 transition-transform text-muted",
                "group-hover/link:translate-x-1 group-hover/link:text-primary",
              )}
            />
          </Link>
          <Separator className="mb-4" />
        </>
      )}

      {!isFetchingMyPendingGenerations && hasPendingGenerations && (
        <AccountPopoverPendingGenerations
          generations={myPendingGenerations as GenerationsTableRow[]}
          user={user}
        />
      )}

      <ul className="w-full flex flex-col">
        <li className="w-full mb-2 rounded-lg">
          <Link href="/">
            <Button
              size="sm"
              className="w-full bg-transparent justify-start"
              variant="secondary"
            >
              <TelescopeIcon className="mr-2" size={16} />
              Explore
            </Button>
          </Link>
        </li>
        <li className="w-full mb-2 rounded-lg">
          <Link href="/account">
            <Button
              size="sm"
              className="w-full bg-transparent justify-start"
              variant="secondary"
            >
              <UserIcon className="mr-2" size={16} />
              Account
            </Button>
          </Link>
        </li>
        <li className="w-full mb-2 rounded-lg">
          <Link href="/pricing#faq">
            <Button
              size="sm"
              className="w-full bg-transparent justify-start"
              variant="secondary"
            >
              <MessageCircleQuestionIcon className="mr-2" size={16} />
              FAQ
            </Button>
          </Link>
        </li>
        <li className="w-full mb-2 rounded-lg">
          <Link href="/pricing">
            <Button
              size="sm"
              className="w-full bg-transparent justify-start"
              variant="secondary"
            >
              <DollarSignIcon className="mr-2" size={16} />
              Pricing
            </Button>
          </Link>
        </li>
        <li className="w-full mb-2 rounded-lg">
          <Link href="https://discord.gg/QnwAqkfrt7" target="_blank">
            <Button
              size="sm"
              className="w-full bg-transparent justify-start"
              variant="secondary"
            >
              <MessageCircle className="mr-2" size={16} />
              Discord
            </Button>
          </Link>
        </li>
        <Separator className="mb-2 md:mb-4" />
        <li className="w-full mb-2 rounded-lg">
          <Button
            size="sm"
            onClick={onSignOutClick}
            className="w-full bg-transparent justify-start"
            variant="secondary"
          >
            <LogOutIcon className="mr-2" size={16} />
            Logout
          </Button>
        </li>
      </ul>
    </ScrollArea>
  )
}
