"use client"

import * as React from "react"

import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { LanguagesIcon } from "lucide-react"
import { usePathname, useRouter } from "@/lib/i18n"
import {
  AvailableLanguageTag,
  languageTag,
  setLanguageTag,
} from "@/paraglide/runtime"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

const labels: Record<AvailableLanguageTag, string> = {
  en: "🇬🇧 English",
  fr: "🇫🇷 Français",
  de: "🇳🇱 Deutsch",
}

export function ChangeLanguageDropdown() {
  const pathname = usePathname()
  const router = useRouter()

  const onLanguageChange = (language: AvailableLanguageTag) => {
    setLanguageTag(language)
    router.push(pathname, { locale: language, scroll: false })
  }

  return (
    <div>
      <span className="sr-only">Change language</span>
      <Select onValueChange={onLanguageChange} defaultValue={languageTag()}>
        <SelectTrigger className="max-w-[180px] md:max-w-[150px] w-full">
          <SelectValue placeholder="Language" />
        </SelectTrigger>
        <SelectContent>
          {Object.keys(labels).map((language) => (
            <SelectItem
              key={language}
              value={language}
              onClick={() => onLanguageChange(language as AvailableLanguageTag)}
            >
              {labels[language as AvailableLanguageTag]}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}
