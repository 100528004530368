"use client"
import { useEffect } from "react"
import { Button } from "@/components/ui/button"
import { useRootStore } from "@/store/root-store"
import { CatIcon, SquirrelIcon } from "lucide-react"
import { track } from "@/services/analytics"

const catUrl = "https://cataas.com/cat"

export function SafeForWorkToggle() {
  const { setIsSafeForWork: setIsSafeForWorkInStore, isSafeForWork } =
    useRootStore((store) => store)

  useEffect(() => {
    let observer: MutationObserver
    const replaceImages = (images: NodeListOf<HTMLImageElement>) => {
      for (let image of images) {
        if (!image.src.startsWith(catUrl)) {
          image.src = `${catUrl}?original=${image.src}`
        }
      }
    }

    // replace all images with cat imaegs
    if (isSafeForWork) {
      const images = document.querySelectorAll("img")
      replaceImages(images)

      observer = new MutationObserver((mutationsList, _observer) => {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            for (let addedNode of mutation.addedNodes) {
              if (addedNode.nodeType != Node.ELEMENT_NODE) {
                return
              }

              if (
                addedNode.nodeName === "img" ||
                addedNode.nodeName === "figure"
              ) {
                let docFragment = document.createDocumentFragment()
                docFragment.appendChild(addedNode)
                return replaceImages(docFragment.children as any)
              }

              //@ts-ignore
              let elements = addedNode.querySelectorAll("img,figure")
              replaceImages(elements)
            }
          } else if (
            mutation.type === "attributes" &&
            //@ts-ignore
            mutation.target.tagName.toLowerCase() === "img"
          ) {
            //@ts-ignore
            if (!mutation.target.src.startsWith(catUrl)) {
              //@ts-ignore
              mutation.target.src = `${catUrl}?${mutation.target.src}`
            }
          }
        }
      })
      observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ["src"],
      })
    }
    return () => {
      observer?.disconnect()
    }
  }, [isSafeForWork])

  const toggleSafeForWork = () => {
    track("clicked_safe_for_work_toggle", { isSafeForWork: !isSafeForWork })
    if (isSafeForWork) {
      window.location.reload()
    }
    setIsSafeForWorkInStore(!isSafeForWork)
  }

  return (
    <div>
      <Button
        variant={isSafeForWork ? "destructive" : "ghost"}
        className="w-9 px-0"
        onClick={toggleSafeForWork}
      >
        {isSafeForWork ? <SquirrelIcon /> : <CatIcon />}
      </Button>
    </div>
  )
}
