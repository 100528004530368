import { Badge } from "@/components/ui/badge"
import { Skeleton } from "@/components/ui/skeleton"
import { TypographyMuted } from "@/components/ui/typography"
import { supabaseClient } from "@/services/supabase/supabase-client"
import { useRootStore } from "@/store/root-store"
import { getGenerationImage } from "@/utils/helpers"
import {
  Database,
  GenerationsTableRowWithUsersProfile,
} from "@/utils/types/database"
import { formatDistanceToNow } from "date-fns"
import { Loader2, SkullIcon } from "lucide-react"
import Image from "next/image"
import { Link } from "@/lib/i18n"
import { useCallback } from "react"
import { toast } from "sonner"
import { match } from "ts-pattern"

interface GenerationsPopoverSectionItemProps {
  generation: GenerationsTableRowWithUsersProfile
}
export function GenerationsPopoverSectionItem(
  props: GenerationsPopoverSectionItemProps,
) {
  const { generation } = props

  const generationImage = getGenerationImage(generation.images)

  const removeMyPendingGeneration = useRootStore(
    (state) => state.removeMyPendingGeneration,
  )
  const removeMyRecentGeneration = useRootStore(
    (state) => state.removeMyRecentGeneration,
  )

  const formattedDeliveredAtDate = generation.delivered_at
    ? formatDistanceToNow(new Date(generation.delivered_at), {
        addSuffix: true,
      })
    : "Never"

  const formattedCreatedAtDate = generation.created_at
    ? formatDistanceToNow(new Date(generation.created_at), { addSuffix: true })
    : null

  const deleteGeneration = useCallback(async () => {
    if (!generation.parameter_id) {
      return
    }

    const { error } = await supabaseClient
      .from("generations_parameters")
      .delete()
      .eq("id", generation.parameter_id)
      .single()

    if (error) {
      console.error("Error deleting generation", error)
      toast.error(error.message, {
        duration: 5000,
      })

      return
    }

    removeMyPendingGeneration(generation)
    removeMyRecentGeneration(generation)

    toast.message("The pending generation has been canceled.", {
      icon: "🔪",
    })
  }, [generation, removeMyPendingGeneration, removeMyRecentGeneration])

  if (generation.status === "DELIVERED") {
    return (
      <Link href={`/images/${generation.id}`} key={generation.id}>
        <div className="flex flex-row items-start justify-start h-14 mb-2">
          <div className="aspect-video w-14 h-14 mr-3">
            <Image
              src={generationImage.url}
              alt="Generation Image"
              width={generationImage.width}
              height={generationImage.height}
              className="rounded-lg h-14"
              placeholder={generation.images.blurhash ? "blur" : "empty"}
              blurDataURL={generation.images.blurhash}
            />
          </div>
          <div className="flex flex-col h-full justify-between">
            <div>
              <StatusComponent status={generation.status}>
                {generation.status}
              </StatusComponent>
            </div>
            <TypographyMuted>About {formattedDeliveredAtDate}</TypographyMuted>
          </div>
        </div>
      </Link>
    )
  }

  const isPending = ["GENERATING", "DELIVERING", "PENDING"].includes(
    generation.status,
  )

  if (isPending) {
    return (
      <Link href={`/images/${generation.id}`} key={generation.id}>
        <div className="flex flex-row items-start justify-start h-14 mb-2">
          <div className="aspect-video w-12 h-full mr-3">
            <Loader2 className="mb-20 h-12 w-12 text-primary/60 animate-spin" />
          </div>
          <div className="flex flex-col h-full justify-between">
            <div className="flex flex-row items-center gap-2">
              <StatusComponent status={generation.status}>
                {generation.status}
              </StatusComponent>
              <Badge
                onClick={deleteGeneration}
                size="sm"
                variant="destructive"
                className="cursor-pointer ml-1"
              >
                CANCEL
              </Badge>
            </div>
            {Boolean(formattedCreatedAtDate) && (
              <TypographyMuted>
                Started {formattedCreatedAtDate}
              </TypographyMuted>
            )}
          </div>
        </div>
      </Link>
    )
  }

  if (generation.status === "FAILED") {
    return (
      <Link href={`/images/${generation.id}`} key={generation.id}>
        <div className="flex flex-row items-start justify-start h-14 mb-2">
          <div className="aspect-video w-14 h-full mr-3 relative">
            <Skeleton className="h-14 w-14 rounded-lg" />
            <div className="h-14 w-14 absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
              <SkullIcon />
            </div>
          </div>
          <div className="flex flex-col h-full justify-between">
            <div className="flex flex-row items-center gap-2">
              <StatusComponent status={generation.status}>
                {generation.status}
              </StatusComponent>
              <Badge
                onClick={deleteGeneration}
                size="sm"
                variant="default"
                className="cursor-pointer"
              >
                DELETE
              </Badge>
            </div>
            {Boolean(formattedCreatedAtDate) && (
              <TypographyMuted>
                Started {formattedCreatedAtDate}
              </TypographyMuted>
            )}
          </div>
        </div>
      </Link>
    )
  }

  return null
}

const StatusComponent = ({
  status,
  children,
}: {
  status: Database["public"]["Enums"]["generation_task_status"] | null
  children: React.ReactNode
}) => {
  return match(status)
    .with("DELIVERED", () => (
      <Badge variant="secondary" size="sm">
        {children}
      </Badge>
    ))
    .with("FAILED", () => (
      <Badge variant="destructive" size="sm">
        {children}
      </Badge>
    ))
    .otherwise(() => (
      <Badge variant="outline" size="sm">
        {children}
      </Badge>
    ))
}
