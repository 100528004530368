"use client"
import { AccountPopoverContentBody } from "@/components/navigation/account-popover-content-body"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { User, useUser } from "@supabase/auth-helpers-react"
import { CrownIcon } from "lucide-react"
import { Link } from "@/lib/i18n"

interface AccountPopoverProps {
  initialUser?: User
}
export function AccountPopover(props: AccountPopoverProps) {
  const { initialUser } = props
  const user = useUser()
  const currentUser = initialUser || user

  if (!currentUser) {
    return (
      <Link href="/login" scroll={false}>
        <Button size="sm" variant="outline">
          Sign In
        </Button>
      </Link>
    )
  }

  const userMetadata = currentUser.user_metadata || {}
  const appMetadata = currentUser.app_metadata || {}

  const username = userMetadata?.full_name || userMetadata?.name || ""
  const isPro = appMetadata?.is_pro

  const initials = (username || currentUser?.email || "")
    .split(" ")
    .map((n: string) => n[0])
    .join("")
    .toUpperCase()

  return (
    <div className="relative">
      <Popover>
        <PopoverTrigger>
          <Avatar className="scale-75 md:scale-100">
            <AvatarImage src={userMetadata?.avatar_url} />
            <AvatarFallback>{initials}</AvatarFallback>
          </Avatar>
        </PopoverTrigger>
        <PopoverContent sideOffset={5} collisionPadding={10}>
          <AccountPopoverContentBody username={username} user={currentUser} />
        </PopoverContent>
      </Popover>
      {isPro && (
        <div className="absolute top-2 md:top-0 right-0 rotate-45 translate-x-1 -translate-y-1/2">
          <CrownIcon size={16} className="mr-1" />
        </div>
      )}
    </div>
  )
}
