"use client"

import { useState } from "react"
import { motion } from "framer-motion"
import { cn } from "@/lib/utils"
import { Link } from "@/lib/i18n"
import { usePathname } from "@/lib/i18n"

type Tab = {
  title: string
  value: string
  href: string
  hrefMatches: string[]
  icon?: React.ReactNode
  className?: string
  //  null (default): Prefetch behavior depends on whether the route is static or dynamic. For static routes, the full route will be prefetched (including all its data). For dynamic routes, the partial route down to the nearest segment with a loading.js boundary will be prefetched.
  // true: The full route will be prefetched for both static and dynamic routes.
  // false: Prefetching will never happen both on entering the viewport and on hover.
  prefetch: boolean | null
  protected?: boolean
}

export const NavTabs = ({
  tabs: propTabs,
  containerClassName,
  activeTabClassName,
  activeButtonClassName,
  tabClassName,
  contentClassName,
}: {
  tabs: Tab[]
  containerClassName?: string
  activeTabClassName?: string
  activeButtonClassName?: string
  tabClassName?: string
  contentClassName?: string
}) => {
  const [active, setActive] = useState<Tab>(propTabs[0])
  const [tabs, setTabs] = useState<Tab[]>(propTabs)
  const pathName = usePathname()

  const moveSelectedTabToTop = (idx: number) => {
    const newTabs = [...propTabs]
    const selectedTab = newTabs.splice(idx, 1)
    newTabs.unshift(selectedTab[0])
    setTabs(newTabs)
    setActive(newTabs[0])
  }

  const [hovering, setHovering] = useState(false)

  return (
    <>
      <div
        className={cn(
          "relative overflow-auto sm:overflow-visible no-visible-scrollbar max-w-full w-full",
          "flex flex-row md:flex-auto",
          "items-center justify-around md:justify-start",
          containerClassName,
        )}
      >
        {propTabs.map((tab, idx) => {
          const isActive = tab.hrefMatches.includes(pathName)

          return (
            <div
              key={tab.title}
              className={cn("w-full md:w-auto", tab.className)}
            >
              <div className="py-1 md:py-3 w-full md:w-auto">
                <Link
                  href={tab.protected ? "/login" : tab.href}
                  className="flex w-full justify-center md:w-auto"
                  prefetch={tab.prefetch as any}
                  scroll={tab.protected ? false : true}
                >
                  <button
                    onMouseEnter={() => {
                      moveSelectedTabToTop(idx)
                      setHovering(true)
                    }}
                    onMouseLeave={() => {
                      setHovering(false)
                    }}
                    className={cn(
                      "relative flex items-center justify-center px-2 md:px-4 py-2 rounded-lg w-full md:w-auto",
                      tabClassName,
                    )}
                    style={{
                      transformStyle: "preserve-3d",
                    }}
                  >
                    {active.value === tab.value && hovering && (
                      <motion.div
                        layoutId="activebuttonbar"
                        transition={{
                          type: "spring",
                          bounce: 0.3,
                          duration: 0.6,
                        }}
                        className={cn(
                          "absolute inset-0 bg-secondary rounded-lg mx-auto w-5/6",
                          activeButtonClassName,
                        )}
                      />
                    )}

                    {/* {isActive && (
                      <motion.div
                        layoutId="clickedbuttonbarbackground"
                        transition={{
                          type: "spring",
                          bounce: 0.3,
                          duration: 0.6,
                        }}
                        className={cn(
                          "absolute inset-0 bg-primary/70 rounded-lg",
                        )}
                      />
                    )} */}

                    <div className="flex flex-row items-center justify-center md:justify-start">
                      {tab.icon && (
                        <span
                          className={cn("md:mr-2 z-30 scale-110 md:scale-100", {
                            "text-black dark:text-white": !isActive,
                          })}
                        >
                          {tab.icon}
                        </span>
                      )}
                      <span
                        className={cn("relative hidden md:block", {
                          "text-black dark:text-white": !isActive,
                        })}
                        style={{
                          transform: "translateZ(30px)",
                          fontWeight: isActive ? "bold" : "normal",
                        }}
                      >
                        {tab.title}
                      </span>
                    </div>
                  </button>
                </Link>
              </div>
              {!isActive && (
                <div className={cn("h-1 bg-transparent w-full rounded-sm")} />
              )}
              {isActive && (
                <motion.div
                  layoutId="clickedbutton"
                  transition={{
                    type: "spring",
                    bounce: 0.3,
                    duration: 0.6,
                  }}
                  className={cn(
                    "h-1 bg-primary w-5/6 mx-auto rounded-sm",
                    activeTabClassName,
                  )}
                />
              )}
            </div>
          )
        })}
      </div>
    </>
  )
}
