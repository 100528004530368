"use client"
import { GenerationsPopoverSection } from "@/components/navigation/generations-popover-section"
import { Separator } from "@/components/ui/separator"
import { TypographySmall } from "@/components/ui/typography"
import { supabaseClient } from "@/services/supabase/supabase-client"
import {
  GenerationsTableRow,
  GenerationsTableRowWithUsersProfile,
} from "@/utils/types/database"
import {
  RealtimeChannel,
  RealtimePostgresUpdatePayload,
  User,
} from "@supabase/supabase-js"
import { take } from "lodash"
import { useEffect, useMemo, useRef, useState } from "react"

interface AccountPopoverPendingGenerationsProps {
  user: User
  generations: GenerationsTableRow[]
}
const AccountPopoverPendingGenerations = (
  props: AccountPopoverPendingGenerationsProps,
) => {
  const { user, generations } = props
  const [pendingGenerations, setPendingGenerations] =
    useState<GenerationsTableRow[]>(generations)
  const realtimeChannel = useRef<RealtimeChannel>()

  useEffect(() => {
    const handleRecordUpdate = (
      payload: RealtimePostgresUpdatePayload<GenerationsTableRow>,
    ) => {
      if (["DELIVERED", "FAILED"].includes(payload.new.status)) {
        setPendingGenerations((prev) => {
          return prev.filter((generation) => generation.id !== payload.new.id)
        })
      }
      if (
        ["GENERATING", "DELIVERING", "PENDING"].includes(payload.new.status)
      ) {
        setPendingGenerations((prev) => {
          return [...prev, payload.new]
        })
      }
    }
    // TODO(Harris): Supabase does not yet support filtering on multiple columns
    // when using realtime. They are working on it however, so for now we get a lot
    // of unncessary events on the client side. Once they support it, we should also
    // filter by status for status=in.(GENERATING,DELIVERING,PENDING)
    const listenToUpdates = async () => {
      realtimeChannel.current = supabaseClient
        .channel("pending-generations-channel")
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "generations",
            filter: `owner_id=eq.${user.id}`,
          },
          handleRecordUpdate,
        )
        .subscribe()
    }
    listenToUpdates()
    return () => {
      realtimeChannel.current?.unsubscribe()
    }
  }, [user.id])

  const samplePendingGenerations = useMemo(() => {
    return take(pendingGenerations, 3)
  }, [pendingGenerations])

  return (
    <>
      <div className="mb-2">
        <div className="mb-2">
          <TypographySmall>Queued Generations</TypographySmall>
        </div>
        <GenerationsPopoverSection
          generations={
            samplePendingGenerations as GenerationsTableRowWithUsersProfile[]
          }
        />
      </div>
      <Separator className="mb-4" />
    </>
  )
}

export { AccountPopoverPendingGenerations }
